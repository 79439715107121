<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="warning" title="Atenção">
                Não existem veículos com módulos de backup ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>
    <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vs-table ref="table" :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." maxItems="500" search hoverFlat stripe>
                <template slot="header">
                    <h6 class="ml-2 mt-2" v-if="listaVeiculos.length > 0 "> {{qtdRegistros}} veículos</h6>                    
                </template>
                <template slot="thead">
                    <vs-th class="p-0">Veículo</vs-th>                    
                    <vs-th class="p-0">Placa</vs-th>                    
                    <vs-th class="p-0">Código Bkp</vs-th>
                    <vs-th class="p-0">Ativação</vs-th>                    
                    <vs-th class="p-0">Fidelização</vs-th>     
                    <vs-th class="p-0">Multa Fidelização</vs-th>               
                    <vs-th class="p-0">Ação </vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td  class="p-0"><small>{{tr.marca}} {{tr.modelo}} </small> </vs-td>
                        <vs-td  class="p-0"><small class="font-bold" style='font-family: Verdana, sans-serif;'> {{tr.placa}} </small></vs-td>
                        <vs-td  class="p-0">
                                <small class="font-bold">{{tr.codigoModulo}}</small>  <small>{{tr.marcaModulo}}</small>
                                <br/>
                                <small class="font-bold">{{tr.numeroGSM}}</small>  <small>{{tr.operadoraGSM}}</small>
                        </vs-td>                        
                        <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.dataAtivacao}}</small></vs-td>                       
                        <vs-td class="p-0">
                            <vx-tooltip :text="getRenovacao(tr)">   
                                <small style='font-family: Verdana, sans-serif;' v-if="tr.prazoFidelizacao > 0">{{tr.prazoFidelizacao}} meses</small>
                            </vx-tooltip>
                            <vx-tooltip text="Fidelização concluída">   
                                <feather-icon icon="CheckIcon" svgClasses="w-4 h-4 stroke-current text-success" v-if="tr.prazoFidelizacao === 0" />
                            </vx-tooltip>
                        </vs-td>                       
                        <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.multaFidelizacao}}</small></vs-td>                       
                        <vs-td  class="p-0">
                            <div class="flex">
                                <vx-tooltip text="Desativar">   
                                    <vs-button size="small" class="mb-1" color="primary" type="filled" icon-pack="feather" icon="icon-arrow-down" @click="desativar(tr)"></vs-button>
                                </vx-tooltip>                                
                            </div>                            
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "@/axios.js"

export default {    
    created() {
        
    },
    data() {
        return {            
            veiculoSelecionado: [{placa: null}],
            listaClientes: [],
            listaVeiculos: [],
            clienteSelecionado: null,    
            exibirMensagemNenhumDadoEncontrado: false,                          
            publicPath: process.env.BASE_URL
        }
    },
    computed:{
        qtdRegistros() {
            return this.$refs.table ? this.$refs.table.queriedResults.length : this.listaVeiculos.length
        }
    },
    methods: {
         getRenovacao(tr) {
                if (tr.numeroRenovacao > 0) {
                    return "Contrato renovado: " + tr.numeroRenovacao;
                } else {
                    return "1a contratação";
                }
        },
        desativar(v) {
            this.veiculoSelecionado = v;
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja desativar o módulo de backup do veículo ' +  self_.veiculoSelecionado.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.desativarRequest
            });
        },
        desativarRequest() {
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.veiculoSelecionado.idVeiculo);   
            params.append('pĺaca', this.veiculoSelecionado.placa);   
            params.append('codigoModulo', this.veiculoSelecionado.codigoModulo);   
            params.append('marcaModulo', this.veiculoSelecionado.marcaModulo);   
                    
            axios.post("/DesativarModuloBackupVeiculo", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                            title: "Mensagem", text: response.data.mensagem, time: 10000,
                            iconPack: 'feather', icon: 'icon-alert-circle', color: 'warning' });
                    this.fetchListaVeiculos();
                                       
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaVeiculos() {     
            if (!this.clienteSelecionado) {
                return;
            }
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            
            axios.post("/RecuperarClientePorCNPJouCPFComModulosBackup", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                    this.exibirMensagemNenhumDadoEncontrado = this.listaVeiculos.length === 0;
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        
    },
    mounted() {               
        this.fetchListaClientes();
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>